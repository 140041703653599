import React from 'react';
import { OpenapiOrderRO } from '@escapenavigator/types/dist/openapi/orders/openapi-order.ro';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { TransactionTypeEnum } from '@escapenavigator/types/dist/transaction/enum/transaction-type.enum';

import { Transaction } from './transaction';

type Props = {
    transactions: OpenapiOrderRO['transactions'];
    currency: ProfileCurrencyEnum;
};

export const TransactionsList: React.FC<Props> = ({ transactions = [], currency }) => {
    if (!transactions.length) return null;

    return (
        <React.Fragment>
            { transactions
                .filter((t) => t.type !== TransactionTypeEnum.CREATED)
                .map((transaction) => (
                    <Transaction currency={ currency } transaction={ transaction } />
                )) }
        </React.Fragment>
    );
};
