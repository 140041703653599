/* eslint-disable no-template-curly-in-string */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GiftM, LocationM, TicketM } from '@alphakits/icons';
import {
    Box,
    CopyButton,
    Divider,
    FlexColumns,
    Image,
    Loader,
    ToastPlate,
    Typography,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks/use-api-method';
import { CrmVerificationEnum } from '@escapenavigator/types/dist/profile/enum/crm-verifiaction.enum';
import { Languages } from '@escapenavigator/types/dist/shared/enum/languages.enum';
import { WidgetTypeEnum } from '@escapenavigator/types/dist/widget/enum/widget-type.enum';
import { WidgetRO } from '@escapenavigator/types/dist/widget/widget.ro';
import { useApi } from 'src/providers/api/context';

import { WidgetGuide } from './widget-component';

import styles from './index.module.css';

type Props = {
    guideId: string;
};

export const Guide: React.FC<Props> = ({ guideId }) => {
    const { widgets } = useApi();
    const { t } = useTranslation();

    const {
        getInstructionData, getInstructionFetch, getInstructionLoading, getInstructionError,
    } =
        useApiMethod({
            api: widgets.getInstruction,
        });

    useEffect(() => {
        getInstructionFetch({ token: guideId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!getInstructionData || getInstructionLoading) {
        return <Loader />;
    }

    if (getInstructionError) {
        return <React.Fragment>{ getInstructionError.message }</React.Fragment>;
    }

    const serializedWidgets = getInstructionData.widgets.reduce((acc, widget) => {
        acc[widget.type] = acc[widget.type] || [];
        acc[widget.type].push(widget);

        return acc;
    }, {} as Record<WidgetTypeEnum, WidgetRO[]>);

    const widgetScriptCode = `<script src="${process.env.REACT_APP_WIDGET_DOMAIN}/widget-loader.js"></script>`;

    return (
        <Box padding="xxl">
            <FlexColumns columns={ 1 } gr={ 48 }>
                { getInstructionData.status !== CrmVerificationEnum.SUCCEED && (
                    <ToastPlate view="neutral" title={ t('widget-guide:testModeTitle') }>
                        { t('widget-guide:testModeDescription') }
                    </ToastPlate>
                ) }
                <FlexColumns columns={ 1 } gr={ 8 }>
                    <Typography.Title tag="div" view="xsmall" weight="bold">
                        { t('widget-guide:guide.title') }
                    </Typography.Title>
                    <Typography.Text tag="div" view="primary-medium" color="primary">
                        { t('widget-guide:guide.share_text') }
                    </Typography.Text>
                </FlexColumns>

                <FlexColumns columns={ 1 } gr={ 8 }>
                    <Typography.Text tag="div" view="title" weight="bold">
                        { t('widget-guide:guide.main_script.title') }
                    </Typography.Text>

                    <Typography.Text tag="div" view="primary-medium" color="primary">
                        { t('widget-guide:guide.main_script.description') }
                    </Typography.Text>

                    <pre className={ styles.pre }>
                        <CopyButton text={ widgetScriptCode } fullText={ widgetScriptCode } />
                    </pre>
                </FlexColumns>

                <WidgetGuide
                    title={ t('widget-guide:guide.all_questrooms.title') }
                    description={ t('widget-guide:guide.all_questrooms.description') }
                    lang={ Languages.DE }
                    widgets={ serializedWidgets[WidgetTypeEnum.ALL_QUESTROOM] }
                    widgetTitle={ (widget) =>
                        (widget.allLocations
                            ? t('widget-guide:guide.all_questrooms.all')
                            : widget.locations.map((l) => l.title).toString()) }
                    widgetImage={ () => <Image icon={ <LocationM /> } /> }
                />

                <WidgetGuide
                    title={ t('widget-guide:guide.button_widget.title') }
                    description={ t('widget-guide:guide.button_widget.description') }
                    lang={ Languages.DE }
                    widgets={ serializedWidgets[WidgetTypeEnum.WIDGET_BUTTON] }
                    widgetTitle={ (widget) => {
                        const locations = widget.allLocations
                            ? t('widget-guide:guide.all_questrooms.all')
                            : widget.locations?.map((l) => l.title).toString();
                        const certificates = widget.allCertificates
                            ? t('widget-guide:guide.all_certificates.all')
                            : widget.certificates?.map((l) => l.title).toString();

                        return `${locations}, ${certificates}`;
                    } }
                    widgetImage={ () => <Image icon={ <TicketM /> } /> }
                />

                <WidgetGuide
                    title={ t('widget-guide:guide.one_questroom.title') }
                    description={ t('widget-guide:guide.one_questroom.description') }
                    lang={ Languages.DE }
                    widgets={ serializedWidgets[WidgetTypeEnum.ONE_QUESTROOM] }
                    widgetTitle={ (widget) => widget.questroom.title }
                    widgetImage={ (widget) => <Image src={ widget.questroom.photo } /> }
                />

                <WidgetGuide
                    title={ t('widget-guide:guide.all_certificates.title') }
                    description={ t('widget-guide:guide.all_certificates.description') }
                    lang={ Languages.DE }
                    widgets={ serializedWidgets[WidgetTypeEnum.ALL_CERTIFICATES] }
                    widgetTitle={ (widget) =>
                        (widget.allCertificates
                            ? t('widget-guide:guide.all_certificates.all')
                            : widget.certificates?.map((l) => l.title).toString()) }
                    widgetImage={ () => <Image icon={ <GiftM /> } /> }
                />

                <FlexColumns columns={ 1 } gr={ 8 }>
                    <Typography.Text tag="div" view="title" weight="bold">
                        { t('widget-guide:guide.params') }
                    </Typography.Text>

                    <Divider />

                    <Typography.Text view="caps" weight="bold">
                        <code>data-theme="light | dark"</code>
                    </Typography.Text>

                    <Typography.Text tag="div" view="primary-medium" color="primary">
                        { t('widget-guide:guide.params_color') }
                    </Typography.Text>

                    <pre className={ styles.pre }>
                        { '<div' } <b>data-theme="light"</b>{ ' ' }
                        {
                            'data-widget-id="SOME_ID" data-main-color="#000" data-widget-type="schedule" />'
                        }
                    </pre>

                    <Divider size="m" />

                    <Typography.Text view="caps" weight="bold">
                        <code>
                            data-language="en | de | et | ru | es | pt | pl | fr | it | el | cs |
                            nl"
                        </code>
                    </Typography.Text>

                    <Typography.Text tag="div" view="primary-medium" color="primary">
                        { t('widget-guide:guide.params_language') }
                    </Typography.Text>

                    <pre className={ styles.pre }>
                        { '<div' } <b>data-language="en"</b>{ ' ' }
                        {
                            'data-widget-id="SOME_ID" data-main-color="#000" data-widget-type="schedule" />'
                        }
                    </pre>

                    <Divider size="m" />

                    <Typography.Text view="caps" weight="bold">
                        <code>data-main-color="#000"</code>
                    </Typography.Text>

                    <pre className={ styles.pre }>
                        { '<div' } <b>data-main-color="#000"</b>{ ' ' }
                        {
                            'data-widget-id="SOME_ID" data-language="en" data-widget-type="schedule" />'
                        }
                    </pre>
                </FlexColumns>
            </FlexColumns>
        </Box>
    );
};
